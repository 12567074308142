@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

form {
  &.light-form{
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: map_get($colors-light-form, label-active);
      -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset !important;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: map_get($colors-form, label-active);
    -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset !important;
  }

  .select-wrapper, .input-wrapper {
    &:not(:nth-last-of-type(1)) {
      margin: 0 0 57px;
    }
  }

  .flex-wrapper{
    display:flex;
    gap: 20px;

    >*{
      width: calc(50% - 10px);
    }
  }

  label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @include ciutadella-regular();
    font-size: rem(18);
    line-height: rem(30.6);
    color: map_get($colors-form, label);

    transition: all 150ms $ease-in-out;

    span {
      color: map_get($colors-form, error);
    }
  }

  .error-msg, .error-message, .error-php-message, span.asterisk {
    color: map-get($colors-form, error);
  }

  .error-msg {
    display: none;
    font-size: rem(12);
    line-height: rem(15.6);
    position: absolute;
    top: 100%;
    left: 0;
    margin: 12px 0 0;
  }

  .errors {
    margin: 20px 0 0;
  }

  .legal{
    @include text-xs;
    margin:0;
  }

  button {
    cursor: pointer;
  }

  [type='submit'] {
    display: block;
    margin: 40px 0 0 auto;
  }

  @media only screen and (max-width: $xs-max) {
    .select-wrapper:not(:nth-last-of-type(1)),
    .input-wrapper:not(:nth-last-of-type(1)) {
      margin: 0 0 50px;
    }

    label {
      font-size: rem(16);
      line-height: rem(25.6);
    }

    [type='submit'] {
      margin: 40px 0 0;
      width: 100%;
    }
  }
}